<template>
	<div>
		<h1>Orphan</h1>
		<button class="btn btn-indigo" @click="check">
			<!-- <font-awesome-icon v-if="isLoading" :icon="['fad', 'cog']" spin /> -->
			Check Orphan
		</button>
		<span v-if="err">{{ err }}</span>

		<div v-for="(o, i) in orphanInfo.ItemData.collection" :key="i">
			<div v-if="o.orphans.length">
				<h2 class="p-4">{{ o.collection | capitalize }}</h2>
				<div class="flex flex-col p-4">
					<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
						<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
							<table class="min-w-full ">
								<thead>
									<tr>
										<th class="thead">ID</th>
										<th class="thead">Account</th>
										<th class="thead">Model Status</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(d, key) in o.orphans" :key="key">
										<td class="td">{{ d.id }}</td>
										<td class="td">
											<button
												:class="d.exists ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'"
												class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4"
												@click="remove(d.id, o.collection)"
											>
												<svg
													:class="d.exists ? 'text-green-400' : 'text-red-400'"
													class="mr-1.5 h-2 w-2 "
													fill="currentColor"
													viewBox="0 0 8 8"
												>
													<circle cx="4" cy="4" r="3" />
												</svg>
												{{ d.account }}
											</button>
										</td>
										<td class="td">
											<ul>
												<li v-for="(d1, key1) in d.assignedToStatus" :key="key1" class="m-2">
													<button
														:class="d1.exists ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'"
														class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4"
														@click="remove(d1.id)"
													>
														<svg
															:class="d1.exists ? 'text-green-400' : 'text-red-400'"
															class="mr-1.5 h-2 w-2 "
															fill="currentColor"
															viewBox="0 0 8 8"
														>
															<circle cx="4" cy="4" r="3" />
														</svg>
														{{ d1.id }}
													</button>
												</li>
											</ul>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="orphanInfo.ModelData.collection.length">
			<div class="flex flex-col p-4">
				<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table class="min-w-full ">
							<thead>
								<tr>
									<th class="thead">Account</th>
									<th class="thead">Model</th>
									<th class="thead">ID</th>
									<th class="thead">Type</th>
								</tr>
							</thead>

							<tbody>
								<tr v-for="(o, i) in orphanInfo.ModelData.collection" :key="i">
									<td class="td">{{ o.account }}</td>
									<td class="td">{{ o.model }}</td>
									<td class="td">
										<button
											:class="o.exists ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'"
											class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4"
											@click="remove({ id: o.model, collection: o.type, uid: o.uid, deleteFunction: deleteRef })"
										>
											<svg
												:class="o.exists ? 'text-green-400' : 'text-red-400'"
												class="mr-1.5 h-2 w-2 "
												fill="currentColor"
												viewBox="0 0 8 8"
											>
												<circle cx="4" cy="4" r="3" />
											</svg>
											{{ o.id }}
										</button>
									</td>
									<td class="td">
										{{ o.type }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div v-show="promptOpen" class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
			<div
				v-show="promptOpen"
				x-transition:enter="ease-out duration-300"
				x-transition:enter-start="opacity-0"
				x-transition:enter-end="opacity-100"
				x-transition:leave="ease-in duration-200"
				x-transition:leave-start="opacity-100"
				x-transition:leave-end="opacity-0"
				class="fixed inset-0 transition-opacity"
			>
				<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
			</div>

			<div
				v-show="promptOpen"
				x-transition:enter="ease-out duration-300"
				x-transition:enter-start="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				x-transition:enter-end="opacity-100 translate-y-0 sm:scale-100"
				x-transition:leave="ease-in duration-200"
				x-transition:leave-start="opacity-100 translate-y-0 sm:scale-100"
				x-transition:leave-end="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
			>
				<div class="sm:flex sm:items-start">
					<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
						<svg class="h-6 w-6 text-red-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
							/>
						</svg>
					</div>
					<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
						<h3 class="text-lg leading-6 font-medium text-gray-900">
							Delete
							<span class=" font-mono text-red-500">{{ promptId }}</span>
							?
						</h3>
						<div class="mt-2">
							<p class="text-sm leading-5 text-gray-500">
								Are you sure you want to deactivate item? All of the data will be permanantly removed from the servers forever. This action
								cannot be undone.
							</p>
						</div>
					</div>
				</div>
				<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
					<span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
						<button
							type="button"
							class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
							@click="removeData.deleteFunction"
						>
							<!-- <font-awesome-icon v-if="isLoading" :icon="['fad', 'cog']" spin /> -->
							<span v-if="isLoading">Loading</span>
							<span v-else>Delete</span>
						</button>
					</span>

					<span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
						<button
							type="button"
							class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
							@click="promptOpen = false"
						>
							Cancel
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import firebase from '@/helpers/firebaseInit.js'
export default {
	name: 'Orphan',
	data: function() {
		return {
			orphanInfo: {
				ItemData: { collection: [] },
				ModelData: { collection: [] }
			},
			promptOpen: false,
			promptId: '',
			promptCollection: null,
			isLoading: false,
			err: '',
			others: ['creators', 'fields', 'channels', 'entities', 'memberships'],
			removeData: {
				deleteFunction: () => {}
			}
		}
	},
	methods: {
		check() {
			this.err = ''
			this.isLoading = true
			var getOrphanedItems = firebase.functions.httpsCallable('adminFunctions-getOrphanedItems')
			this.orphanInfo = {
				ItemData: { collection: [] },
				ModelData: { collection: [] }
			}
			getOrphanedItems()
				.then((result) => {
					this.orphanInfo = result.data
					this.isLoading = false
				})
				.catch((err) => {
					this.isLoading = false
					this.err = err
				})
		},
		remove(data) {
			this.removeData = data
			this.promptOpen = true
		},
		logA() {},
		logB() {},
		deleteRef() {
			let data = {
				id: this.removeData.id,
				collection: this.removeData.collection,
				uid: this.removeData.uid
			}

			var deleteRefFunction = firebase.functions.httpsCallable('adminFunctions-deleteRef')
			deleteRefFunction(data)
				.then(() => {
					this.promptOpen = false
					this.isLoading = false
				})
				.catch((err) => {
					this.isLoading = false
					this.err = err
				})
		},
		deleteItems() {
			this.err = ''
			this.isLoading = true
			if (this.promptCollection) {
				let data = {
					id: this.promptId,
					collection: this.promptCollection
				}
				var deleteItem = firebase.functions.httpsCallable('adminFunctions-deleteItem')
				deleteItem(data)
					.then(() => {
						this.promptOpen = false
						this.promptCollection = null
						this.isLoading = false
					})
					.catch((err) => {
						this.isLoading = false
						this.err = err
					})
			} else {
				let data = {
					id: this.promptId
				}
				var deleteItems = firebase.functions.httpsCallable('adminFunctions-deleteItems')
				deleteItems(data)
					.then(() => {
						this.promptOpen = false
						this.isLoading = false
					})
					.catch((err) => {
						this.isLoading = false
						this.err = err
					})
			}
		}
	}
}
</script>
